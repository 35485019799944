
import { mapState } from "vuex";
import IconActionCards from "@/components/cta/IconActionCards.vue";
import { Component, Vue } from "vue-property-decorator";
import { UFliterals } from "@/utils/literals";
import { ISequence } from "@/schemas/ISequence";
import { SessionState } from "@/schemas/Enums";
import SequenceForm from "@/components/admin/entityForm/SequenceForm.vue";
import TipGroupForm from "@/components/admin/entityForm/TipGroupForm.vue";
import ActivityForm from "@/components/admin/entityForm/ActivityForm.vue";

@Component({
  name: "AdminBoard",
  components: { ActivityForm, TipGroupForm, SequenceForm, IconActionCards },
  computed: {
    ...mapState(["creationToolsTabs", "trainerHelpTabs"]),
  },
})
export default class AdminBoard extends Vue {
  creationToolsTabs:
    | {
        id?: string;
        slug: string;
        storeModuleName: string;
        stateEntityList: string;
        title: string;
      }[]
    | undefined;
  trainerHelpTabs:
    | {
        id?: string;
        slug: string;
        storeModuleName: string;
        stateEntityList: string;
        title: string;
      }[]
    | undefined;
  submitRankingHandler!: any;
  headers = [
    {
      text: this.getUF("title"),
      align: "start",
      value: "title",
    },
    { text: this.getUF("code"), value: "code" },
    { text: this.getUF("created_at"), value: "created_at" },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      width: "110px",
    },
  ];
  createdSequences: ISequence[] = [];
  finishedSequences: ISequence[] = [];
  runningSequences: ISequence[] = [];

  dialog = false;
  dialogCancel = false;
  editedSequence: ISequence = {};
  dialogConsult = false;
  idLength = 8;

  redirectToTest() {
    window.open ("https://speedtest.eccipe.limos.fr/", '_blank');
  }

  created(): void {
    this.$store.dispatch("sequence/fetchSequencesByStatus", {
      state: SessionState.Created,
      callback: (sequences: ISequence[]) => {
        this.createdSequences = sequences;
      },
    });
    this.$store.dispatch("sequence/fetchSequencesByStatus", {
      state: SessionState.RunningGroup,
      callback: (sequences: ISequence[]) => {
        this.runningSequences = sequences;
      },
    });
    this.$store.dispatch("sequence/fetchSequencesByStatus", {
      state: SessionState.Finished,
      callback: (sequences: ISequence[]) => {
        this.finishedSequences = sequences;
      },
    });
  }

  getCards(
    tabs:
      | {
          id?: string;
          slug: string;
          storeModuleName: string;
          stateEntityList: string;
          title: string;
        }[]
      | undefined
  ): { id: string; title: string; action: CallableFunction; icon: string }[] {
    let cards:
      | { title: string; action: CallableFunction; icon: string }[]
      | { title: any; action: () => void; icon: any }[] = [];
    tabs.forEach(
      (tab: {
        slug: string;
        storeModuleName: any;
        stateEntityList: any;
        title: any;
      }) => {
        cards.push({
          id: tab.id,
          title: tab.title,
          action: () => this.redirectToTab(tab),
          icon: tab.img,
        });
      }
    );
    return cards;
  }

  redirectToTab(tabDetails: {
    slug: string;
    storeModuleName: any;
    stateEntityList: any;
    title: any;
  }): void {
    if (tabDetails.slug.includes("crud")) {
      this.$router.push({
        name: "crud",
        params: {
          slug: tabDetails.slug.substr(
            tabDetails.slug.indexOf("/") + 1,
            tabDetails.slug.length
          ),
          storeModuleName: tabDetails.storeModuleName,
          stateEntityList: tabDetails.stateEntityList,
          title: tabDetails.title,
        },
      });
    } else {
      this.$router.push({
        name: tabDetails.slug,
      });
    }
  }

  redirectToSequence(sequenceId: string): void {
    this.$router.push({
      name: "sequence",
      params: {
        id: sequenceId,
      },
    });
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  editSequence(sequence: ISequence): void {
    this.$store.dispatch(`sequence/fetchFullById`, {
      id: sequence.id,
      next: (fetched: Record<string, any>) => {
        this.editedSequence = fetched;
        this.$nextTick(function () {
          this.dialog = true;
        });
      },
    });
  }

  viewSequence(sequence: ISequence): void {
    this.$store.dispatch(`sequence/fetchFullById`, {
      id: sequence.id,
      next: (fetched: Record<string, any>) => {
        this.editedSequence = fetched;
        this.$nextTick(function () {
          this.dialogConsult = true;
        });
      },
    });
  }

  leaveConsult(): void {
    this.dialogConsult = false;
    this.editedSequence = Object.assign({}, {});
  }

  updateSequence(editedSequence: ISequence): void {
    this.$store.dispatch(`sequence/updateSequence`, {
      sequence: editedSequence,
      next: (updated: ISequence) => {
        let sequenceIndex = this.createdSequences.findIndex(
          (seq) => seq.id === editedSequence.id
        );
        if (sequenceIndex != -1) {
          Vue.set(this.createdSequences, sequenceIndex, updated);
        }
        this.dialog = false;
        this.editedSequence = {};
      },
    });
  }

  cancelItemConfirm(): void {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedSequence = {};
    });
    this.dialogCancel = false;
  }
}
